(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/marketplace/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/modules/marketplace/assets/javascripts/init.js');

'use strict';

const {
  ErrorBoundary
} = svs.components.errorBoundary;
const {
  MarketplaceRouterV2
} = svs.marketplace.components.router;
const {
  Revealer
} = svs.components.marketplace.revealLink;
const {
  DialogWrapper
} = svs.components.marketplaceShared.dialogWrapper;
const {
  createStore,
  middlewares,
  reducers
} = svs.components.marketplaceData.store;
const {
  chatReducer
} = svs.components.chat.chatData.reducers;
const {
  userSession
} = svs.core;
const {
  auth
} = svs.components.marketplaceData;
const {
  MarketplaceBrandProvider
} = svs.components.marketplace.marketplaceBrand;
const {
  closeDialog
} = svs.components.marketplaceData.dialogWrapper.actions;
const {
  useDispatch,
  useSelector
} = ReactRedux;
svs.marketplace.store = createStore({
  initialState: svs.marketplace.data.initialState,
  middlewares: [ReduxThunk.default, middlewares.searchQueryMiddleware, middlewares.createKibanaMiddleware(), middlewares.browseGamesMiddleware, middlewares.errorStatusMiddleware, middlewares.gaMiddleware, middlewares.localStorageMiddleware],
  reducers: {
    customerId: function () {
      let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return state;
    },
    Auth: reducers.authReducer,
    BetProposal: reducers.betProposalReducer,
    BrowseGamesFilter: reducers.browseGamesFilterReducer,
    BrowseGamesResults: reducers.browseGamesResultsReducer,
    Chat: chatReducer,
    Config: reducers.configReducer,
    CreateBetProposal: reducers.createBetProposalReducer,
    CreateGame: reducers.createGameReducer,
    CreateTeam: reducers.createTeamReducer,
    Draws: reducers.drawsReducer,
    FractionPayment: reducers.fractionPaymentReducer,
    Games: reducers.gamesReducer,
    GamesExcludeFetch: reducers.gamesExcludeFetch,
    LoadingSingleGames: reducers.loadingSingleGames,
    GamesHistory: reducers.gamesHistoryReducer,
    InviteMembers: reducers.inviteMembersReducer,
    JoinTeam: reducers.joinTeamReducer,
    Members: reducers.memberReducer,
    Dialog: reducers.dialogWrapperReducer,
    Teams: reducers.teamReducer,
    Races: reducers.racesReducer,
    Meetings: reducers.meetingsReducer,
    extratipset: function () {
      let state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      return state;
    }
  }
});
const DialogWrapperRenderer = () => {
  const dispatch = useDispatch();
  const onDialogGlose = () => {
    dispatch(closeDialog);
  };
  const dialogInfo = useSelector(state => state.Dialog);
  return React.createElement(DialogWrapper, {
    dialogInfo: dialogInfo,
    onClose: onDialogGlose
  });
};
ReactDOM.render(React.createElement(React.StrictMode, null, React.createElement(ErrorBoundary, null, React.createElement(ReactRedux.Provider, {
  store: svs.marketplace.store
}, React.createElement(MarketplaceBrandProvider, null, React.createElement(Revealer, null, React.createElement(DialogWrapperRenderer, null), React.createElement(MarketplaceRouterV2, null)))))), document.getElementById('marketplace'));
userSession.on('logout', () => {
  svs.marketplace.store.dispatch(auth.actions.logOut());
  window.location.href = '/';
});

 })(window);